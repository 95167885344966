import React from 'react';
import SEO from '../components/seo';
import { FullHeightBox } from '../components/FullHeightBox';
import { Box, Image, Text, Heading, Button } from 'grommet';
import Layout from '../components/Layout';
import not_found from '../images/illustrations/404.svg';
import { navigate } from 'gatsby';

const NotFoundPage = () => (
  <Layout>
    <FullHeightBox sx={{ flexDirection: 'row' }}>
      <SEO title="404: Not found" />
      <Box
        width="xxlarge"
        justify="center"
        alignSelf="center"
        align="center"
        pad="xlarge"
      >
        <Box width="large">
          <Image src={not_found} />
        </Box>
        <Heading level="1">OH SNAP!</Heading>
        <Text>You just hit a route that doesn&#39;t exist... the sadness.</Text>
        <Button
          onClick={() => navigate('/')}
          label="Go back home"
          margin="large"
          primary
        />
      </Box>
    </FullHeightBox>
  </Layout>
);

export default NotFoundPage;
